<template>
    <addPerson v-if="openAdd" @closeModal="openAdd = false" :type="'change'"/>
    <div class="mainStyle" :class="{mainStyle3: $store.state.role == 'supervisor'}">
        <div class="mainStyle__left">
            <platformTable />
        </div>
        <div class="mainStyle__right">
            <div class="mainStyle__right__top">
                <div class="mainStyle__right__top__left" v-if="$store.state.role == 'curator'">
                    <p class="mainStyle__right__top__left__title">Пользователи</p>
                    <p style="color: #0000008A;" class="mainStyle__right__top__left__text">{{users.length}} человек</p>
                </div>
                <div class="mainStyle__right__top__left" v-if="$store.state.role == 'supervisor'">
                    <p style="color: #0000008A;">Кураторы ></p>
                    <p class="mainStyle__right__top__left__title">Клиенты Иван И.</p>
                </div>
                <div class="mainStyle__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="table2">
                <div class="table2__top">
                    <div class="table2__top__left">
                        <img src="@/assets/icons/achieveIcon.svg" />
                        <p>Список клиентов</p>
                    </div>
                    <div class="table2__top__right">
                        <div class="table2__top__right__export" v-if="$store.state.role == 'supervisor'" @click="openAdd = true">
                            <img src="@/assets/icons/change.svg" />
                            <p>Перенести пользователей</p>
                        </div>
                        <div class="table2__top__right__search">
                            <input type="search" placeholder="Поиск по ФИО" v-model="searchModel"/>
                            <img src="@/assets/icons/find.svg" />
                        </div>
                        <div class="table2__top__right__export" v-if="$store.state.role == 'curator'" @click="downloadExel()">
                            <p>Экспортировать данные</p>
                            <img src="@/assets/icons/export.svg" />
                        </div>
                        <div class="table2__top__right__filter" @click="openFilter = !openFilter"
                            :class="{ customFilter: openFilter }">
                            <p>Фильтр</p>
                            <img src="@/assets/icons/filter.svg" />
                        </div>
                        <div class="table2__top__right__select">
                            <p>Сортировка:</p>
                            <customSelect :is-border="true" :list="sort" v-model="sortBy" @change="applyFilters"/>
                        </div>
                    </div>
                </div>
                <div class="selects" v-if="openFilter">
                    <div class="selects__top">
                        <div class="selects__top__in">
                            <p>Выберите пакет</p>
                            <customSelect :list="packages" v-model="selectedPackage"/>
                        </div>
                        <div class="selects__top__in">
                            <p>Год поступления</p>
                            <customSelect :list="years" v-model="selectedYear"/>
                        </div>
                        <div class="selects__top__in">
                            <p>С рисками</p>
                            <customSelect :list="risks" v-model="selectedRisk"/>
                        </div>
                        <div class="selects__top__in">
                            <p>Статус клиента</p>
                            <customSelect :list="statuses" v-model="selectedStatus"/>
                        </div>
                        <div class="selects__top__in">
                            <p>Этап</p>
                            <customSelect :list="stages" v-model="selectedStage"/>
                        </div>
                        <div class="selects__top__in">
                            <p>Подача</p>
                            <customSelect :list="supplyList" v-model="selectedSupply"/>
                        </div>
                    </div>
                    <div class="selects__bot">
                        <button class="selects__bot__save" @click="applyFilters()">Применить</button>
                        <button class="selects__bot__delete" @click="resetFilters()">Сбросить</button>
                    </div>
                </div>
                <div class="customTable">
                    <div class="customTable__left">
                        <div class="customTable__left__top">
                            <input type="checkbox" />
                            <p>Имя пользователя</p>
                        </div>
                        <div class="customTable__left__bot" v-for="item in users" :key="item">
                            <input type="checkbox" />
                            <div class="customTable__left__bot__person" @click="$router.push('/curator/users/1')">
                                <img src="@/assets/icons/person.svg" />
                                <div class="customTable__left__bot__person__info">
                                    <p class="customTable__left__bot__person__info__id">ID: {{ item.user.id }}</p>
                                    <p class="customTable__left__bot__person__info__name">{{ item.user.name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="customTable__right" v-if="opentable == 1">
                        <div class="customTable__right__top">
                            <p class="customTable__right__top__status">Статус клиента</p>
                            <p class="customTable__right__top__progress">Прогресс бар</p>
                            <p class="customTable__right__top__et1">Этап</p>
                            <p class="customTable__right__top__ielts">IELTS</p>
                            <p class="customTable__right__top__risk">С рисками</p>
                            <img src="@/assets/icons/blackSelect.svg" @click="opentable = 2" class="customTable__right__select"/>
                        </div>
                        <div class="customTable__right__bot" v-for="item in users" :key="item.id">
                            <div class="customTable__right__bot__status" :class="{greenText: item.user.status == 'Активный'}">
                                {{ item.user.status }}
                            </div>
                            <div class="customTable__right__bot__progress">{{ item.user.progress }}%</div>
                            <div class="customTable__right__bot__et1">{{item.user.stage}}</div>
                            <div class="customTable__right__bot__ielts">{{item.user.ielts}}</div>
                            <div class="customTable__right__bot__risk">
                                <p v-if="item.user.risks == true">Да</p>
                                <p v-else>Нет</p>
                            </div>
                            <div class="customTable__right__bot__img"></div>
                        </div>
                    </div>
                    <div class="customTable__right" v-if="opentable == 2">
                        <div class="customTable__right__top">
                            <p class="customTable__right__top__year">Год поступления</p>
                            <p class="customTable__right__top__packet">Пакет</p>
                            <p class="customTable__right__top__year">Подача</p>
                            <p class="customTable__right__top__packet">Степень</p>
                            <p class="customTable__right__top__curator">Действие</p>
                            <img src="@/assets/icons/blackSelect.svg" style="rotate: 180deg;" @click="opentable = 1" class="customTable__right__select"/>
                        </div>
                        <div class="customTable__right__bot" v-for="item in users" :key="item.id">
                            <div class="customTable__right__bot__year">{{item?.user?.created_at}}</div>
                            <div class="customTable__right__bot__packet">{{item?.user?.package?.title}}</div>
                            <div class="customTable__right__bot__year">{{item?.user?.supply_text}}</div>
                            <div class="customTable__right__bot__packet">{{item?.user?.study_degree?.title}}</div>
                            <div class="customTable__right__bot__curator" style="cursor: pointer;">
                                <img src="@/assets/icons/heart.svg" v-if="item.is_client_of_day == 1" @click="deleteClient(item.user.id)"/>
                                <img src="@/assets/icons/greyHeart.svg" v-else @click="newClient(item.user.id)"/>
                            </div>
                            <div class="customTable__right__bot__img"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import customSelect from "@/components/customSelect.vue";
import platformTable from "@/components/platformTable.vue";
import userInfo from "@/components/userInfo.vue";
import addPerson from "@/components/addPerson.vue";
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow])
import axios from "axios";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo,
        customSelect,
        addPerson
    },
    data() {
        return {
            openFilter: false,
            opentable: 1,
            openAdd: false,
            selectedStage: '',
            selectedPackage: null,
            searchModel: '',
            selectedYear: null,
            selectedRisk: null,
            selectedStatus: null,
            selectedSupply: null,
            users: [],
            sortBy: '',
            sort:[
                {
                    title: 'Выбор',
                    id: 0,
                    type1: ''
                },
                {
                    title: 'От А до Я',
                    id: 1,
                    type1: 'name',
                    order_by: 'asc'
                },
                {
                    title: 'От Я до А',
                    id: 2,
                    type1: 'name',
                    order_by: 'desc'
                },
                {
                    title: 'Сначала активные',
                    id: 3,
                    type1: 'status',
                    order_by: 'asc'
                },
                {
                    title: 'Сначала проблемные',
                    id: 4,
                    type1: 'status',
                    order_by: 'desc'
                },
                {
                    title: 'С рисками',
                    id: 5,
                    type1: 'risk'
                }
            ],
            supplyList: [
                {
                    id: 0,
                    title: 'Первая'
                },
                {
                    id: 1,
                    title: 'Переподача'
                }
            ],
            packages: [
                { id: 1, title: "Bolashak" },
                { id: 2, title: "Hungaricum" },
                { id: 3, title: "Erasmus" },
                { id: 4, title: "DSU" },
                { id: 5, title: "Платное поступление" },
            ],
            years: [
                { id: 1, title: 2025 },
                { id: 2, title: 2026 },
                { id: 3, title: 2027 },
            ],
            risks: [
                { id: 1, title: "Да" },
                { id: 2, title: "Нет" },
            ],
            statuses: [
                { id: 1, title: "Активный" },
                { id: 2, title: "Неактивный" },
                { id: 3, title: "Проблемный" },
            ],
            stages: [
                { id: 1, title: "1 этап" },
                { id: 2, title: "2 этап" },
                { id: 3, title: "3 этап" },
                { id: 4, title: "4 этап" },
            ],
        };
    },
    methods:{
        downloadExel() {
            axios.get('/platform/export/users', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
                responseType: 'blob' // Указываем, что ожидаем бинарные данные (файл)
            })
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'users.xlsx'; // Имя файла
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                    toast.success('Файл успешно скачан!');
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => { 
                console.error('Ошибка при скачивании файла:', error);
            });
        },
        async applyFilters() {
            const selectedSort = this.sort.find(item => item.id == this.sortBy).type1;
            const selectedOrder = this.sort.find(item => item.id == this.sortBy).order_by;
            const filters = {
                search: this.searchModel,
                package_ids: this.selectedPackage,
                admission_year: this.selectedYear,
                is_risk: this.selectedRisk,
                status: this.selectedStatus,
                stage: this.selectedStage,
                sort_by: selectedSort,
                order_by: selectedOrder
            };

            const response = await axios.get("/platform/curator/users", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
                params: filters,
            });

            this.users = response.data.data;
        },
        resetFilters() {
            this.selectedPackage = null;
            this.selectedYear = null;
            this.selectedRisk = null;
            this.selectedStatus = null;
            this.selectedStage = null;
            this.sortBy = '';
            this.applyFilters(); // Применяем сброс фильтров
        },
        newClient(id){
            const data = {
                user_id: id
            }
            axios.post('/platform/curator/store-client-of-day', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
            if (response.status === 201) {
                toast.success('Успешно добавлено!')
                this.getPage()
            } else {
                console.log(response.data);
            }
            })
            .catch((response) => { 
                console.log(response.response.data.errors);
            });
        },
        deleteClient(id){
            axios.delete(`/platform/curator/delete-client-of-day/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
            if (response.status === 200) {
                toast.success('Успешно удалено!')
                this.getPage()
            } else {
                console.log(response.data);
            }
            })
            .catch((response) => { 
                console.log(response.response.data.errors);
            });
        },
        async getPage() {
            await axios.get(`/platform/curator/users`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then(res => {
                this.users = res.data.data
            })
            .catch(err => {
            })
        },
    },
    async created(){
        this.getPage()
    }
};
</script>
<style lang="scss" scoped>
.line {
    border-top: 1px solid #0000001A;
    width: 100%;
}

select {
    border: 1px solid #0000001A;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}
.customHeart{
    filter: brightness(0) saturate(100%) invert(17%) sepia(95%) saturate(7482%) hue-rotate(2deg) brightness(110%) contrast(116%) !important;
}
.greenText{
    color: green !important;
}
.customFilter {
    background: #11415D;

    p {
        color: white !important;
    }

    img {
        filter: brightness(0) saturate(100%) invert(99%) sepia(94%) saturate(3%) hue-rotate(179deg) brightness(104%) contrast(100%);
    }
}
.table2 {
    display: flex;
    background-color: #FFFFFF;
    flex-direction: column;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    padding: 10px;

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__left {
            display: flex;
            gap: 10px;
            align-items: center;
            width: 15%;

            img {
                width: 40px;
            }
        }

        &__right {
            display: flex;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            width: 80%;
            gap: 10px;
            justify-content: flex-end;
            align-items: stretch;
            font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);

            img {
                width: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                height: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                cursor: pointer;
            }

            &__select,
            &__export {
                max-width: 35%;
                width: fit-content;
                p{
                    width: 100%;
                }
            }

            &__search {
                width: 20%;
                border: 1px solid #0000001A;
                padding: 10px;
                border-radius: 10px;
                position: relative;
                cursor: pointer;

                img {
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    right: 5px;
                }

                input {
                    border: none;
                    font-style: italic;
                    width: 100%;
                    height: 100%;
                }
            }

            &__select,
            &__export,
            &__filter {
                display: flex;
                cursor: pointer;
                gap: 5px;
                border-radius: 10px;
                align-items: center;
                justify-content: space-between;
                border: 1px solid #0000001A;
                padding: 0px 10px;

                p {
                    color: #0000008A;
                }

                select {
                    border: none;
                }
            }
        }
    }

    .selects {
        display: flex;
        flex-direction: column;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        width: 100%;

        &__top {
            display: flex;
            flex-wrap: wrap;
            gap: 3.5%;
            row-gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

            &__in {
                width: 31%;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                gap: 15px;
                p{
                    font-weight: 600;
                }
            }
        }

        &__bot {
            display: flex;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);

            button {
                width: 20%;
                border: none;
                padding: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.5208))), 12px)0px;
                border-radius: 10px;
            }

            &__save {
                color: white;
                font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                background-color: #11415D;
                transition: all 0.5s ease;

                &:hover {
                    color: white;
                    background-color: #2076a7;
                }
            }

            &__delete {
                background-color: inherit;
                font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                border: 1px solid #0000001A !important;
                color: #0000008A;
                transition: all 0.5s ease;

                &:hover {
                    color: white;
                    background-color: #11415D;
                    border: 1px solid #11415D;
                }
            }
        }
    }
}
</style>