<template>
    <div class="table1">
        <div class="table1__top">
            <img src="@/assets/logo.svg"/>
            <div class="table1__top__right">
                AI TECH
            </div>
        </div>
        <div class="table1__block">
            <div class="table1__block__title">
                <p>Основное</p>
                <div class="line"></div>
            </div>
            <div class="table1__block__in" v-for="item in tabs" :key="item" @click="$router.push(`${item.route}`)" :class="{customTab : $route.path == item.route || $route.path == item?.route1}" v-if="$store.state.role == 'user'">
                <img src="@/assets/icons/fullHeart.svg" v-if="$route.path == '/testFavourite' && $route.path == item.route"/>
                <img :src="require('@/assets/icons/' + item.img + '.svg')" v-else/>
                <p>{{ item.name }}</p>
            </div>
            <div class="table1__block__in" v-for="(item, index) in tabsCurator" :key="index" @click="$router.push(`${item.route}`)" :class="{customTabCurator : $route.path == item.route || $route.path == item?.route1}" v-if="$store.state.role == 'curator'">
                <img :src="require('@/assets/icons/' + item.img + '.svg')"/>
                <p>{{ item.name }}</p>
            </div>
            <div class="table1__block__in" v-for="(vis, index) in tabsSupervisor" :key="vis" @click="$router.push(`${vis.route}`)" :class="{customTabVisor : $route.path == vis.route || $route.path == vis?.route1}" v-if="$store.state.role == 'supervisor'">
                <img :src="require('@/assets/icons/' + vis.img + '.svg')"/>
                <p>{{ vis.name }}</p>
            </div>
            <div class="table1__block__in" @click="leaveAccount()">
                <img src="@/assets/icons/tab_8.svg"/>
                <p>Выйти</p>
            </div>
        </div>
        <div class="table1__news" v-if="$store.state.role == 'user'">
            <div class="table1__news__title">
                <p>Новости</p>
                <div class="line"></div>
            </div>
            <div class="table1__news__in" v-for="item in news" :key="item">
                <img :src="item.image"/>
                {{ item.title }}
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from "axios";
export default {
    data(){
        return{
            isCurator: true,
            tabsSupervisor: [
            {
                    img: 'tab_1',
                    name: 'Главная страница',
                    route:'/supervisor'
                },
                {
                    img: 'tabc_3',
                    name: 'Пользователи',
                    route:'/supervisor/users'
                },
                {
                    img: 'tab_1',
                    name: 'Кураторы',
                    route:'/supervisor/curators'
                },
                {
                    img: 'tab_8',
                    name: 'Платформа',
                    route:'/supervisor/platform'
                },
                {
                    img: 'tabc_4',
                    name: 'Уведомления',
                    route: '/curator/notifications'
                },
            ],
            tabsCurator: [
                {
                    img: 'tab_1',
                    name: 'Главная страница',
                    route: '/curator'
                },
                {
                    img: 'tabc_2',
                    name: 'Программы',
                    route: '/curator/programs',
                },
                {
                    img: 'wait',
                    name: 'В ожидании',
                    route: '/curator/waitPrograms',
                },
                {
                    img: 'tabc_3',
                    name: 'Пользователи',
                    route: '/curator/users'
                },
                {
                    img: 'tabc_4',
                    name: 'Уведомления',
                    route: '/curator/notifications'
                },
            ],
            tabs:[
                {
                    img: 'tab_1',
                    name: 'Главная страница',
                    route: '/testPlatform'
                },
                {
                    img: 'tab_2',
                    name: 'Поиск программ',
                    route: '/testPlatformFind',
                    route1: '/testPlatformFind/1',
                },
                {
                    img: 'tab_3',
                    name: 'Избранное',
                    route: '/testFavourite'
                },
                {
                    img: 'tab_4',
                    name: 'Поступление',
                    route: '/testPlatform/admission'
                },
                {
                    img: 'tab_5',
                    name: 'Международные гранты',
                    route: '/testPlatform/intGrants'
                },
                {
                    img: 'tab_6',
                    name: 'Языковая и тест. школа',
                    route: '/testPlatform/schools'
                },
                {
                    img: 'tab_9',
                    name: 'Премиум опции',
                    route: '/testPlatform/premium'
                },
                {
                    img: 'tab_7',
                    name: 'Полезное',
                    route: '/testPlatform/useful'
                },
            ],
            news: [
            ]
        }
    },
    methods: {
        async getPage() {
            await axios.get(`/V1/articles`)
                .then(res => {
                    this.news = res.data.data
                })
                .catch(err => {
                })
        },
    ...mapActions(["logoutUser"]),
    leaveAccount() {
      this.logoutUser();
      toast("Вы вышли с аккаунта.");
      this.$router.push('/login')
    }
    },
    
    async created() {
        this.getPage();

    },
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #FFFFFF1A;
    height: 1px;
    width: 50%;
}
.customTab{
    background: white;
    border-radius: 5px;
    color: #11415D !important   ;
    img{
        filter: brightness(0) saturate(100%) invert(18%) sepia(12%) saturate(7315%) hue-rotate(175deg) brightness(90%) contrast(87%);
    }
}
.customTabVisor{
    background: white;
    border-radius: 5px;
    color: #2BC38E !important   ;
    img{
        filter: brightness(0) saturate(100%) invert(61%) sepia(67%) saturate(476%) hue-rotate(108deg) brightness(93%) contrast(82%);
    }
}
.customTabCurator{
    background: white;
    border-radius: 5px;
    width: 100%;
    color: #FD2D2A !important;
    img{
        filter: brightness(0) saturate(100%) invert(43%) sepia(76%) saturate(3165%) hue-rotate(336deg) brightness(90%) contrast(122%) !important; 
    }
}
    .table1{
        margin: 0px !important;
        padding: 10px min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
        width: 100% !important;
        display: flex;
        flex-direction: column;
        gap: 40px;
        &__top{
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            img{
                width: 60%;
            }
            &__right{
                width: 36%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;
                font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                font-weight: 700;
                border-radius: 5px;
            }
        }
        &__block{
            display: flex;
            flex-direction: column;
            gap: 10px;
            font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
            width: 100%;
            &__title{
                display: flex;
                gap: 10px;
                align-items: center;
                color: #FFFFFF8A;
            }
            &__in{
                display: flex;
                width: 100%;
                align-items: center;
                padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                color: white;
                cursor: pointer;
                img{
                    width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                    object-fit: cover;
                    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(117deg) brightness(99%) contrast(100%);
                }
            }
        }
        &__news{
            display: flex;
            flex-direction: column;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            &__title{
                display: flex;
                gap: 10px;
                align-items: center;
                color: #FFFFFF8A;
                font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
            }
            &__in{
                width: 100%;
                aspect-ratio: 1.3;
                background-size: cover;
                background-color: #FFFFFF4D;
                border-radius: 8px;
                color: #FFFFFF;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                padding: 10px;
                img{
                    width: 100%;
                    aspect-ratio: 1.78;
                    object-fit: cover;
                    border-radius: 8px;
                }
            }
        }
    }
</style>