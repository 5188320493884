<template>
    <NewProgram v-if="openNewProgram" @closeModal="openNewProgram = false" />
    <div class="page">
        <div class="world">
            <div class="world__left">
                <p class="customBold">Выберите международную стипендию</p>
                <div class="world__left__block">
                    <div class="world__left__block__in" v-for="(item, index) in stips" :key="item"
                        @click="selectStip(item, index)" :class="{ customStip: selectedStip == index }">
                        <div class="world__left__block__in__top">
                            <img :src="item.image" />
                        </div>
                        <p>{{ item.title }}</p>
                    </div>
                </div>
            </div>
            <div class="world__right">
                <p class="customBold">Выберите степень</p>
                <div class="world__right__block">
                    <div class="world__right__block__in1" v-for="(item, index) in steps" :key="item"
                        @click="selectStep(item, index)" :class="{ customStep: selectedStep == index }">
                        <div class="world__left__block__in__top">
                            <img :src="item.image" />
                        </div>
                        <p>{{ item.title }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="category">
            <div class="customBold" style="display: flex; justify-content: space-between;"> 
                <p>Выберите категорию</p>
                <p @click="clearFilters()" style="cursor: pointer;">Очистить фильтр</p>
            </div>
            <div class="category__block">
                <div class="category__block__in" v-for="(item, index) in categories" :key="item"
                    @click="selectCat(item, index)" :class="{ customStip: selectedCat == index }">
                    <div class="category__block__in__top">
                        <img :src="item.icon" />
                    </div>
                    <p style="text-align: center;">{{ item.title }}</p>
                </div>
            </div>
        </div>
        <div class="inputs">
            <div class="customInput">
                <label>Выберите подкатегорию</label>
                <customSelect :list="subCategories" v-model="subCatId" @change="updateFilters" />
            </div>
            <div class="customInput">
                <label>Выберите страну</label>
                <customSelect :list="countries" v-model="countryId" @change="updateFilters" />
            </div>
            <div v-for="filter in filters" :key="filter.id" class="customInput">
                <label>{{ filter.value }}</label>
                <customSelect :list="filter.values.map(value => ({ id: value.id, title: `${value.value}` }))"
                v-model="selectedFilterIds[filter.value]" @change="updateFilters" :isFilter="true"/>
            </div>
            <div class="range">
                <p>Стоимость обучения</p>
                <rangeUi 
                :min="0"
                :max="200000"
                :range='[parseInt(price_range.min), parseInt(price_range.max)]' 
                :step="1" 
                :value="'$'"
                :name="`price_vac-${index}`"
                class="rangeUI-reverse filterVac__item-range"
                @updatemin="(data) => ((price_range.min) = data, updateFilters())"
                @updatemax="(data) => ((price_range.max) = data, updateFilters())"
                />
            </div>
            <div class="customInput" v-if="stepId == 4">
                <label>GRE/GMAT</label>
                <customSelect :list="justGre" v-model="graValue" @change="updateFilters()"/>
            </div>
            <div class="customInput" v-if="stepId == 2 || stepId == 3">
                <label>SAT</label>
                <customSelect :list="greGmat" v-model="satValue" @change="updateFilters()"/>
            </div>
            <div class="range">
                <p>Application fee</p>
                <rangeUi 
                :min="0"
                :max="300"
                :range='[parseInt(appFee.min), parseInt(appFee.max)]' 
                :step="1" 
                :value="'$'"
                :name="`price_vac-${index}`"
                class="rangeUI-reverse filterVac__item-range"
                @updatemin="(data) => ((appFee.min) = data, updateFilters())"
                @updatemax="(data) => ((appFee.max) = data, updateFilters())"
                />
            </div>
        </div>
        <div class="filter">
            <div class="filter__search" :class="{bigSearch: $store.state.role == 'user'}">
                <input type="search" placeholder="Поиск программ..." style="font-style: italic;" v-model="searchModel" @input="updateFilters"/>
                <img src="@/assets/icons/find.svg" />
            </div>
            <div class="filter__images">
                <img src="@/assets/icons/redChange.svg" />
                <img src="@/assets/icons/redFilter.svg" />
            </div>
            <div class="filter__select">
                <customSelect :list="sort" v-model="sortBy" @change="updateFilters()"/>
            </div>
            <div class="filter__plus" @click="openNewProgram = true" v-if="$store.state.role == 'curator'">
                <img src="@/assets/icons/plus.svg" />
                <p>Добавить программу</p>
            </div>
        </div>
        <div class="block">
            <div class="block__in" v-for="item in programs" :key="item">
                <NewCardComponent :item="item" @newFav="newFav()" />
            </div>
        </div>
    </div>
</template>

<script>
import customSelect from "@/components/customSelect.vue";
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import "swiper/swiper.min.css";
import NewCardComponent from "@/components/newCardComponent.vue";
import NewProgram from "@/components/newPlatform/newProgram.vue";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow])
import axios from "axios";
import { SliderComponent} from "@syncfusion/ej2-vue-inputs";
import rangeUi from "./rangeUi.vue";
export default {
    components: {
        Swiper,
        SwiperSlide,
        NewCardComponent,
        NewProgram,
        customSelect,
        rangeUi 
    },
    data() {
        return {
            price_range: {
                min: 0,
                max: 200000
            },
            appFee: {
                min: 0,
                max: 300
            },
            subCatId: null,
            stipId: null,
            stepId: null,
            satValues: {
                min: 0,
                max: 1600
            },
            sortBy: '',
            catId: null,
            filters: [], // Список всех фильтров с их значениями
            countryId: null,
            openNewProgram: false,
            activeTab: 'list',
            selectedStip: 0,
            selectedStep: 0,
            categories: false,
            programs: false,
            selectedCat: -1,
            subCategories: [],
            sort: [
                {
                    title: 'Выбор',
                    id: 0,
                    type1: '',
                    order_by: 'asc'
                },
                {
                    title: 'Топ-50',
                    id: 1,
                    type1: 'top',
                    order_by: 'asc'
                },
                {
                    title: 'Топ-250',
                    id: 2,
                    type1: 'top',
                    order_by: 'desc'
                },
                {
                    title: 'Партнеры',
                    id: 3,
                    type1: 'is_partners',
                    order_by: 'asc'
                },
                {
                    title: 'Выбор наших менти',
                    id: 4,
                    type1: 'is_student_choose',
                    order_by: 'asc'
                },
            ],
            greGmat: [
                {
                    id: 1,
                    title: 'Требуется'
                },
                {
                    id: 0,
                    title: 'Не требуется'
                },
            ],
            justGre: [
                {
                    id: 1,
                    title: 'Требуется'
                },
                {
                    id: 0,
                    title: 'Не требуется'
                },
                {
                    id: 2,
                    title: 'Рекомендуется'
                },
            ],
            graValue: null,
            selectedFilterIds: {},
            value: [30, 70],
            type: 'Range',
            searchModel: '',
            countries: [],
            satValue: null,
            steps: false,
            stips: false,
        };
    },
    methods: {
        newFav() {
            this.getPage();
        },
        selectStep(item, index) {
            if (this.selectedStep == index) {
                this.selectedStep = -1
                this.stepId = null
            }
            else {
                this.selectedStep = index
                this.stepId = item.id
            }
        }, selectStip(item, index) {
            if (this.selectedStip == index) {
                this.selectedStip = -1
                this.stipId = null
            }
            else {
                this.selectedStip = index
                this.stipId = item.id
            }
        }, selectCat(item, index) {
            if (this.selectedCat == index) {
                this.selectedCat = -1
                this.subCategories = []
                this.catId = null
            }
            else {
                this.selectedCat = index
                this.subCategories = item.subCategories
                this.catId = item.id
            }
        },
        async loadFilters() {
    try {
        // Получение списка типов фильтров
        const filterTypesResponse = await axios.get("/platform/filter-types");
        const filterTypes = filterTypesResponse.data.data;
        console.log(filterTypes);
        
        // Исключаем фильтры с названиями "toefl" и "Application fee"
        const filteredFilterTypes = filterTypes.filter(filter => filter.value != 'TOEFL' && filter.value !== 'Application fee');

        // Асинхронно загружаем значения для каждого типа фильтра
        const filtersWithValues = await Promise.all(
            filteredFilterTypes.slice(0, -1).map(async (filter) => {
                const valuesResponse = await axios.get(`/platform/filter-values?type=${filter.id}`);
                return {
                    ...filter,
                    values: valuesResponse.data.data,
                };
            })
        );

        this.filters = filtersWithValues;

        // Инициализируем объект selectedFilters
        filtersWithValues.forEach((filter) => {
            this.$set(this.selectedFilters, filter.id, null);
        });
    } catch (error) {
        console.error("Error loading filters:", error);
    }
},
        async getPage() {
            await axios.get(`/platform/scholarships`, {
            })
                .then(res => {
                    this.stips = res.data.data
                    this.stipId = res.data.data[0].id
                })
                .catch(err => {
                })
            await axios.get(`/platform/countries`, {
            })
                .then(res => {
                    this.countries = res.data.data
                })
                .catch(err => {
                })
            await axios.get(`/platform/study-degrees`, {
            })
                .then(res => {
                    this.steps = res.data.data
                    this.stepId = res.data.data[0].id
                })
                .catch(err => {
                })
            await axios.get(`/V1/universityPrograms`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
            })
                .then(res => {
                    this.programs = res.data.universityPrograms
                })
                .catch(err => {
                })
            await axios.get(`/platform/categories`, {
            })
                .then(res => {
                    this.categories = res.data.data
                })
                .catch(err => {
                })
        },
        async updateFilters() {
            try {
                const newSort = this.sort.find(item => item.id == this.sortBy)?.type1
                const newOder = this.sort.find(item => item.id == this.sortBy)?.order_by
                const payload = {
                    sub_category_id: this.subCatId,
                    country_id: this.countryId,
                    category_id: this.catId,
                    // scholarship_id: this.stipId,
                    // study_degree_id: this.stepId,
                    sort: newSort,
                    order_by: newOder,
                    is_gra: this.graValue,
                    is_sat: this.satValue,
                    price_to: this.price_range.max,
                    price_from: this.price_range.min,
                    search: this.searchModel,
                    application_fee_to: this.appFee.max,
                    application_fee_from: this.appFee.min,
                    ...Object.fromEntries(
                        Object.entries(this.selectedFilterIds || {}).map(([key, value]) => {
                            if (value != null) {
                                const newKey = key === "Продолжительность обучения" ? "duration" : key.toLowerCase();
                                return [`${newKey}_value_id`, value];
                            }
                            return [];
                        }).filter(entry => entry.length > 0)
                    ),
                };
                // Отправляем запрос
                const response = await axios.get("/V1/universityPrograms", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                    params: payload,
                });

                // Если запрос успешен, обновляем this.programs
                this.programs = response.data.universityPrograms;

                // Для отладки (опционально)
                console.log("Filters updated successfully:", this.programs);
            } catch (error) {
                // Обрабатываем ошибку
                console.error("Error updating filters:", error);
            }
        },
        clearFilters() {
            this.selectedCat = -1
            this.selectedFilterIds = {}; // Очистка всех выбранных фильтров
            this.searchModel = ""; // Очистка строки поиска
            this.sortBy = null; // Сброс сортировки
            this.subCatId = null;
            this.countryId = null;
            this.catId = null;
            this.updateFilters();
        }
    },
    async created() {
        this.getPage();
        this.loadFilters()
    },
};
</script>

<style lang="scss" scoped>
.customBold {
    font-weight: 600;
    color: #0000008A;
    font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px) !important;
}
.bigSearch{
    width: 80% !important;
}
.page {
    display: flex;
    flex-direction: column;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
}

.line {
    border-top: 1px solid #0000001A;
    width: 100%;
}
.range{
    width: 23.5%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.inputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2%;
    row-gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);

    @media(max-width:768px) {
        display: none;
    }
}

.customInput {
    width: 23.5%;
}

.customStip, .customStep {
    color: white !important;
    background: #FD2D2A !important;
    font-weight: 600;
    .world__left__block__in__top {
        background-color: white !important;
    }
    .category__block__in__top {
        background-color: white !important;
    }
    .line {
        border-top: 1px solid white !important;
    }
}
.customStep{
    img{
        filter: brightness(0) saturate(100%) invert(32%) sepia(76%) saturate(4782%) hue-rotate(347deg) brightness(105%) contrast(97%) !important;
    }
}
.world {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    &__left {
        width: 60%;

        &__block {
            gap: 2.5%;

            &__in {
                &__top {
                    padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)10px;
                    width: 100%;
                    border-radius: 10px;

                    img {
                        width: 100%;
                    }

                    border: 1px solid #0000001A;
                }

                width: 18%;
            }
        }
    }

    &__right {
        width: 32%;

        &__block {
            gap: 5%;

            &__in1 {
                width: 30%;
            }
        }
    }

    &__left,
    &__right {
        display: flex;
        flex-direction: column;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

        &__block {
            display: flex;
            flex-wrap: wrap;
            row-gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);

            &__in,
            &__in1 {
                cursor: pointer;
                display: flex;
                padding: 8px 8px 10px;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                border-radius: 10px;
                font-weight: 600;
                text-align: center;
                img {
                    height: 35px;
                    object-fit: contain;
                }
            }

            &__in1 {
                background-color: #FFFFFF;

                img {
                    height: 35px;
                    filter: brightness(0) saturate(100%) invert(71%) sepia(1%) saturate(503%) hue-rotate(114deg) brightness(98%) contrast(96%);
                }
            }

            &__in {
                color: #FD2D2A;
                background: white;
                .line {
                    border-top: 1px solid #FD2D2A;
                }
            }
        }
    }

    @media(max-width:768px) {
        display: none;
    }
}

.category {
    display: flex;
    flex-direction: column;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

    &__block {
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        &__in {
            width: 11%;
            background-color: white;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            padding: 8px 8px 10px;
            justify-content: flex-start;
            border-radius: 10px;
            cursor: pointer;

            &__top {
                padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)10px;
                width: 100%;
                border-radius: 10px;
                display: flex;
                height: 60px;
                align-items: center;
                justify-content: center;

                p {

                    text-align: center;
                }

                img {
                    width: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                }

                border: 1px solid #0000001A;
            }
        }
    }

    @media(max-width:768px) {
        display: none;
    }
}

select {
    border: 1px solid #0000001A;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}

.filter {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 30px;
    img {
        width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        height: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
    }

    &__images {
        display: none;
        align-items: center;
        justify-content: center;
        gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        width: 23%;
    }

    input,
    select,
    &__plus {
        width: 100%;
        padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        background-color: transparent;
        border: 1px solid #0000001A;
        box-shadow: 0px 0px 4px 0px #0000000D;
        border-radius: 10px;
        height: 100%;
    }

    &__search {
        width: 60%;
        position: relative;
        img {
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            right: 10px;
            cursor: pointer;
        }
    }

    &__select {
        width: 15%;

        select {
            padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)5px;
        }
    }

    &__plus {
        width: 23%;
        cursor: pointer;
        display: flex;
        gap: 10px;
        align-items: center;
    }

    @media(max-width:768px) {
        &__search {
            width: 75%;
        }

        &__plus {
            display: none;
        }

        &__select {
            display: none;
        }

        &__images {
            display: flex;
        }
    }
}

.block {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

    &__in {
        width: 49%;
    }

    @media(max-width:768px) {
        row-gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

        &__in {
            width: 100%;
        }
    }
}
</style>