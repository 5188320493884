<template>
    <success v-if="openSuccess" @closeModal="handleCloseSuccess" />
    <div class="program" :class="{ customProgram: $store.state.role == 'curator' }">
        <div class="program__left">
            <platformTable />
        </div>
        <div class="program__right">
            <div class="program__right__top">
                <div class="program__right__top__title" v-if="$store.state.role == 'user'">
                    Поиск программ > <span> {{ newData?.programName }}</span>
                </div>
                <div class="program__right__top__title" v-else>
                    Пользователи > Максим М. > <span>Избранный ВУЗ</span>
                </div>
                <div class="program__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="forCurator" :class="{ customCurator: $store.state.role == 'curator' }">
                <div class="videoBlock">
                    <div class="videoBlock__swiperBlock" v-if="$store.state.role == 'user'">
                        <div class="videoBlock__swiperBlock__top">
                            <img src="@/assets/icons/arrowRight.svg" class="rightArrow" />
                            <img src="@/assets/icons/arrowRight.svg" style="rotate: 180deg;" class="leftArrow" />

                            <Swiper ref="topSwiper" class="videoBlock__swiperBlock__top__swiper"
                                :navigation="{ nextEl: '.rightArrow', prevEl: '.leftArrow' }" :speed="400" :lazy="true"
                                :loop="true" :initialSlide="activeSlide" :breakpoints="{
                                    0: {
                                        slidesPerView: 1,
                                        spaceBetween: 0
                                    }
                                }">
                                <SwiperSlide v-for="(item, index) in newData.images" :key="index"
                                    class="videoBlock__swiperBlock__top__swiper__slide">
                                    <img :src="item.image" v-if="!item.isVideo" />
                                    <iframe v-if="item.isVideo"
                                        src="https://www.youtube.com/embed/tdTg-LNi6eI?si=2-D-yxstQl9n2zWn"
                                        frameborder="0" allowfullscreen width="100%" height="100%"></iframe>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                        <div class="videoBlock__swiperBlock__bot">
                            <Swiper class="videoBlock__swiperBlock__bot__swiper" @swiper="setThumbsSwiper"
                                @slideChange="onSlideChange" :centerInsufficientSlides="true" :lazy="true" :loop="false"
                                :speed="1000" :breakpoints="{
                                    0: {
                                        slidesPerView: 4,
                                        spaceBetween: 20
                                    }
                                }">
                                <SwiperSlide v-for="(item, index) in newData.images" :key="index"
                                    class="videoBlock__swiperBlock__bot__swiper__slide"
                                    :style="{ backgroundImage: !item.isVideo ? `url(${item.image})` : 'none' }">
                                    <iframe v-if="item.isVideo"
                                        style="border-radius: 20px;"
                                        src="https://www.youtube.com/embed/tdTg-LNi6eI?si=2-D-yxstQl9n2zWn"
                                        frameborder="0" allowfullscreen width="100%" height="100%"></iframe>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                    <div class="videoBlock__info">
                        <div class="videoBlock__info__left">
                            <div class="videoBlock__info__left__rate">ТОП-{{ newData?.top }}</div>
                            <div class="videoBlock__info__left__info">
                                <p class="videoBlock__info__left__info__text">{{ newData?.programName }}</p>
                                <p class="customTitle">{{ newData?.university?.title }}</p>
                            </div>
                        </div>
                        <div class="videoBlock__info__right">
                            <div class="videoBlock__info__right__heart">
                                <img src="@/assets/icons/heart.svg" v-if="newData.is_favourite == 0" @click="addToFav()"/>
                                <img src="@/assets/icons/fullHeart.svg" v-if="newData.is_favourite == 1" @click="deleteFromFav()"/>
                            </div>
                            <a :href="newData?.link" class="videoBlock__info__right__world">
                                <img src="@/assets/icons/tab_5.svg" />
                                <div class="redLine"></div>
                                <p>Сайт программы</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="program__right__desc">
                    <p class="customTitle">Описание</p>
                    <p class="program__right__desc__text" :class="{ 'clamped': isClamped }">{{
                        convertHtmlToText(newData?.description) }}</p>
                    <span @click="toggleClamped">{{ isClamped ? 'Скрыть' : 'Подробнее' }}</span>
                </div>
                <div class="program__right__info">
                    <p class="customTitle">Подробная информация</p>
                    <div class="program__right__info__block">
                        <div class="program__right__info__block__in" v-for="item in programInfo" :key="item">
                            <img :src="require('@/assets/icons/' + item.image + '.svg')" />
                            <p class="program__right__info__block__in__label">{{ item.label }}</p>
                            <div class="line"></div>
                            <p class="program__right__info__block__in__value">{{ item.value }}</p>
                        </div>
                    </div>
                </div>
                <div class="program__right__areas">
                    <div class="program__right__areas__left">
                        <p class="program__right__areas__left__title">Академические требования</p>
                        <div class="line"></div>
                        <div v-html="newData.workExperience"></div>
                    </div>
                    <div class="program__right__areas__left">
                        <p class="program__right__areas__left__title">Перечень документов</p>
                        <div class="line"></div>
                        <div v-html="newData.docs"></div>
                    </div>
                </div>
                <div class="program__right__other">
                    <p class="program__right__other__title">Другое</p>
                    <div class="line"></div>
                    <div></div>
                </div>
                <div class="program__right__map">
                    <p class="customTitle">На карте мира</p>
                    <div class="program__right__map__image" :class="{ customMap: openMap == true }"
                        @click="openMap = !openMap">
                        <GoogleMap api-key="AIzaSyDaC4rs2OfcZhf3Pnn9uegUI6h8dUnu2fY" style="width: 100%; height: 100%"
                            :center="center" :zoom="15">
                            <Marker :options="{ position: center }" />
                        </GoogleMap>
                    </div>
                </div>
                <div class="program__right__old" v-if="$store.state.role == 'user'">
                    <p class="customTitle">Нашли устаревшую информацию?</p>
                    <div class="program__right__old__block">
                        <textarea placeholder="...." v-model="comment"></textarea>
                    </div>
                </div>
                <div class="program__right__files" v-if="file">
                    <img src="@/assets/icons/file.svg" />
                    <p>{{ file.name }}</p>
                </div>
                <div class="program__right__buttons" :class="{ customButtons: $store.state.role == 'curator' }">
                    <button class="program__right__buttons__send" @click="sendSuccess()"
                        v-if="$store.state.role == 'user'">
                        <img src="@/assets/icons/send.svg" />
                        <p>Отправить</p>
                    </button>
                    <button class="program__right__buttons__send" v-else>
                        <p>Добавить в поступление</p>
                    </button>
                    <label class="program__right__buttons__file" v-if="$store.state.role == 'user'"
                        for="program_document">
                        <img src="@/assets/icons/plus.svg" />
                        <p>Добавить файл</p>
                        <input type="file" id="program_document" style="display: none;" @change="handleFileChange" />
                    </label>
                    <button class="program__right__buttons__file" v-else>
                        <p>Редактировать</p>
                    </button>
                </div>
                <!-- <GMapMap
                :center="center"
                :zoom="7"
                map-type-id="terrain"
                style="width: 500px; height: 300px"
            >
                <GMapCluster>
                <GMapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="true"
                    @click="center=m.position"
                />
                </GMapCluster>
            </GMapMap> -->
                <div class="line" v-if="$store.state.role == 'user'"></div>
                <div class="program__right__more" v-if="$store.state.role == 'user'">
                    <p class="customTitle">Похожие программы</p>
                    <div class="program__right__more__block">
                        <div class="program__right__more__block__in" v-for="item in newData?.similarPrograms"
                            :key="item">
                            <NewCardComponent :item="item" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from '@/components/platformTable.vue';
import userInfo from '@/components/userInfo.vue';
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, Thumbs } from 'swiper';
import "swiper/swiper.min.css";
import success from '@/components/newPlatform/success.vue';
import NewCardComponent from '@/components/newCardComponent.vue';
import img_1 from '@/assets/icons/test.png'
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, Thumbs])
import axios from "axios";
import { GoogleMap, Marker } from 'vue3-google-map'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
    components: {
        platformTable, userInfo, Swiper, SwiperSlide, NewCardComponent, success, GoogleMap, Marker
    },
    data() {
        return {
            thumbsSwiper: '',
            center: { lat: 42.34964619184934, lng: -71.25924450000001 },
            openSuccess: false,
            file: '',
            comment: '',
            openMap: false,
            isClamped: false,
            isTextLongEnough: false,
            newData: [],
            programInfo: [
                {
                    label: "Уровень",
                    value: `TOП-30`,
                    image: "img_1"
                },
                { label: "Код программы", value: "876875", image: "img_2" },
                { label: "Длительность", value: "1 год", image: "img_3" },
                { label: "Стоимость", value: "+150k/yr", image: "img_4" },
                { label: "Страна", value: "США", image: "img_5" },
                { label: "Город", value: "Кембридж", image: "img_6" },
                { label: "GPA", value: "3.8", image: "img_7" },
                { label: "IELTS / TOEFL", value: ">5", image: "img_8" },
                { label: "GRE/GMAT", value: "мин. 5", image: "img_9" },
                { label: "Deadline 1", value: "10.12.2024", image: "img_10" },
                { label: "Крайний дедлайн", value: "12.12.2024", image: "img_10" },
                { label: "Application fee", value: "50 USD", image: "img_4" },
            ]
        }
    },
    methods: {
        addToFav(){
            const data = {
                university_program_id: this.newData.id
            }
            axios.post('/platform/client/users/favourites', data , {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
            if (response.status === 201) {
                toast.success('Добавлено в избранное')
                this.getPage();
            } else {
                console.log(response.data);
            }
            })
            .catch((response) => { 
                console.log(response.response.data.errors);
            }); 
        },
        deleteFromFav(){
            axios.delete(`/platform/client/users/favourites/${this.newData.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
            if (response.status === 202) {
                toast.success('Успешно удалено')
                this.getPage();
            } else {
                console.log(response.data);
            }
            })
            .catch((response) => { 
                console.log(response.response.data.errors);
            }); 
        },
        sendSuccess() {
            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('text', this.comment);
            formData.append('university_program_id', 14);
            try {
                axios.post(`/platform/client/university-program-comment`, formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });
                toast.success('Успешно добавлено!')
                this.file = '';
            } catch (response) {
                toast.error(response.response.data.message);
            }
        },
        handleFileChange() {
            this.file = event.target.files[0];
            console.log(this.file);

        },
        convertHtmlToText(html) {
            if (!html) return '';

            return html
                .replace(/<br\s*\/?>/gi, '\n') // Заменяем <br> на перенос строки
                .replace(/<\/p>/gi, '\n\n') // Добавляем перенос строки после </p>
                .replace(/<[^>]*>/g, '') // Удаляем все HTML-теги
                .replace(/&nbsp;|&#160;/gi, ' ') // Меняем &nbsp; и его кодировку на пробел
                .replace(/&amp;/g, '&') // Декодируем &amp;
                .replace(/&lt;/g, '<') // Декодируем &lt;
                .replace(/&gt;/g, '>') // Декодируем &gt;
                .replace(/\s+/g, ' ') // Убираем лишние пробелы
                .trim(); // Очищаем пробелы по краям
        },
        setThumbsSwiper(item) {
            this.thumbsSwiper = item
        },
        async getPage() {
            await axios.get(`/V1/universityPrograms/14`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.newData = res.data.program
                    this.updateProgramInfo();
                    const lines = this.newData?.description.split("\n").length;
                    this.newData.images = [
                        ...(this.newData.images || []),
                        { isVideo: true, videoUrl: "https://www.youtube.com/embed/tdTg-LNi6eI?si=2-D-yxstQl9n2zWn" }
                    ];
                })
                .catch(err => {
                })
        },
        updateProgramInfo() {
            this.programInfo = [
                { label: "Уровень", value: `ТОП-${this.newData.top}`, image: "img_1" },
                { label: "Код программы", value: `${this.newData.code}`, image: "img_2" },
                { label: "Длительность", value: `${this.newData.duration}`, image: "img_3" },
                { label: "Стоимость", value: `${this.newData.price}`, image: "img_4" },
                { label: "Страна", value: `${this.newData.country}`, image: "img_5" },
                { label: "Штат", value: "Кембридж", image: "img_6" },
                { label: "Город", value: `${this.newData.city}`, image: "img_city" },
                { label: "GPA", value: `${this.newData.gpa}`, image: "img_7" },
                { label: "IELTS", value: ">5", image: "img_8" },
                { label: "TOEFL", value: ">5", image: "img_8" },
                { label: "GRE/GMAT", value: "мин. 5", image: "img_9" },
                { label: "Deadline 1 (Ранняя подача)", value: "10.12.2024", image: "img_10" },
                { label: "Deadline 2 (Крайний срок)", value: "12.12.2024", image: "img_10" },
                { label: "Application fee", value: "50 USD", image: "img_4" },
                { label: "WES", value: "Требуется", image: "img_11" },
                { label: "Partnership", value: "Партнер", image: "img_12" },
                { label: "Выбор студента", value: "Да", image: "img_13" },
            ];
        },
        toggleClamped() {
            this.isClamped = !this.isClamped; // Toggle between clamped and expanded view
        },
        handleCloseSuccess() {
            if (this.openSuccess) {
                this.openSuccess = false;
            }
        },
    },
    async created() {
        this.getPage();
    },
}
</script>
<style lang="scss" scoped>
.customButtons {
    justify-content: center;
}

.customTitle {
    font-size: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.3889))), 36px);
    font-weight: 600 !important;
    overflow: hidden;
    height: -webkit-fill-available !important;
    color: #000;
    
}

.line {
    border: 1px solid #0000001A;
    width: 100%;
}

.redLine {
    border: 1px solid #FD2D2A;
    height: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
}

.leftArrow,
.rightArrow {
    position: absolute;
    width: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
    height: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
    top: 50%;
    transform: translate(-50%, 0%);
    /* Центрирование относительно родительского элемента */
    z-index: 2;
    cursor: pointer;
}

.leftArrow {
    left: 0px;
}

.rightArrow {
    right: 0px;
}

.forCurator {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.customProgram {
    background: linear-gradient(180deg, #210C0C 0%, #C42D2B 53.75%) !important;
}

.customCurator {
    background-color: white;
    padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    border-radius: 10px;
}

.videoBlock {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &__swiperBlock {
        display: flex;
        flex-direction: column;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

        &__top {
            width: 100%;
            position: relative;

            &__swiper {
                width: 100%;
                border-radius: 20px;

                &__slide {
                    width: 100%;
                    aspect-ratio: 2;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        &__bot {
            display: flex;
            justify-content: space-between;
            align-items: stretch;

            &__swiper {
                width: 100%;

                &__slide {
                    width: 100%;
                    aspect-ratio: 2;
                    border-radius: 20px;
                    position: relative;
                    background-image: url('/src/assets/icons/test.png');
                    background-position: center;
                    background-size: cover;
                }
            }

            .swiper-slide-thumb-active {
                &::before {
                    content: "";
                    background-image: url("@/assets/Eye.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    position: absolute;
                    background-color: #0000008A;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 20px;
                }
            }
        }
    }

    &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__left {
            display: flex;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            align-items: stretch;

            &__rate {
                padding: 10px min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #FD2D2A;
                border-radius: 10px;
                font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                font-weight: 600;
                color: white;
            }

            &__info {
                display: flex;
                flex-direction: column;

                &__text {
                    color: #0000008A;
                }
            }
        }

        &__right {
            display: flex;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            align-items: center;

            img {
                width: 27px;
                filter: brightness(0) saturate(100%) invert(25%) sepia(51%) saturate(4554%) hue-rotate(349deg) brightness(108%) contrast(109%);
            }

            &__heart,
            &__world {
                padding: 10px min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                border: 1px solid #FD2D2A;
                border-radius: 10px;
                cursor: pointer;
            }

            &__world {
                display: flex;
                gap: 5px;
                align-items: center;
                font-weight: 700;
                color: #FD2D2A;
            }
        }
    }
}

.program {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #11415D 0px, #2488C3 500px);
    padding-top: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    min-height: 100vh;

    &__left {
        width: 20%;
    }

    &__right {
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        display: flex;
        flex-direction: column;
        gap: 40px;

        &__files {
            display: flex;
            gap: 10px;
            align-items: center;

            img {
                width: 30px;
                height: 30px;
            }
        }

        &__other {
                width: 100%;
                border: 1px solid #0000001A;
                border-radius: 10px;
                padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                display: flex;
                flex-direction: column;
                gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        }

        &__areas {
            display: flex;
            justify-content: space-between;

            &__left {
                width: 48%;
                border: 1px solid #0000001A;
                border-radius: 10px;
                padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                display: flex;
                flex-direction: column;
                gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            }
        }

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__title {
                font-size: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.3889))), 36px);
                ;
                color: #0000008A;

                span {
                    font-weight: 600;
                    color: black;
                }
            }

            &__right {
                width: 25%;
            }
        }

        &__desc {
            display: flex;
            flex-direction: column;

            span {
                font-weight: 700;
                color: black;
                cursor: pointer;
            }

            &__text {
                margin-top: 25px;
                font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
                color: #0000008A;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                /* Clamp to 8 lines */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .clamped {
                display: block;
                /* Forces the full content display when toggled */
                -webkit-line-clamp: unset;
                /* Remove line-clamp when expanded */
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);

            &__block {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                gap: 5%;
                row-gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);

                &__in {
                    width: 10%;
                    display: flex;
                    flex-direction: column;
                    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                    align-items: center;
                    text-align: center;
                    cursor: pointer;

                    img {
                        width: 30%;
                        aspect-ratio: 1;
                        height: auto;
                    }

                    &:nth-child(1) &__value {
                        color: #FD2D2A;
                    }

                    &__label {
                        flex-grow: 1 !important;
                    }

                    &__value {
                        font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                        font-weight: 700;
                        color: #11415D;
                    }
                }
            }
        }

        textarea {
            width: 100%;
            cursor: pointer !important;
            height: 200px;
            padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            border: 1px solid #e0e0e0;
            /* Цвет рамки */
            border-radius: 8px;
            font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            background-color: inherit;
            font-weight: bold;
            color: #000;
            /* Цвет текста */
            outline: none;
            resize: none;
        }

        &__map {
            display: flex;
            flex-direction: column;
            gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);

            .customMap {
                aspect-ratio: 2;
            }

            &__image {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: flex-end;
                justify-content: center;
                cursor: pointer;
                width: 100%;
                aspect-ratio: 5;
                object-fit: cover;
                background-position: center;
                background-size: cover;
                height: 100%;
                border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                border: 1px solid #0000001A;
                box-shadow: 0px 8px 8px 2px rgba(0, 0, 0, 0.05);
                color: white;
                position: relative;

                p {
                    z-index: 1;
                }

                img {
                    width: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                    height: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                    z-index: 1;
                }
            }
        }

        &__old {
            display: flex;
            flex-direction: column;
            gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);

            &__block {
                display: flex;
                justify-content: space-between;

                p {
                    width: 48%;
                    font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                    line-height: 42px;
                    color: #0000008A;
                }
            }
        }

        &__buttons {
            display: flex;
            gap: 2%;

            button,
            label {
                width: 23.5%;
                padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)0px;
                border-radius: 10px;
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            img {
                width: 22px;
            }

            &__send {
                background-color: #11415D;
                color: white;
                border: none;
            }

            &__file {
                border: 1px solid #0000001A;
                background-color: inherit;
            }
        }

        &__more {
            display: flex;
            flex-direction: column;
            gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);

            &__block {
                display: flex;
                flex-wrap: wrap;
                gap: 4%;
                row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

                &__in {
                    width: 48%;
                }
            }
        }
    }
}
</style>