<template >
    <div class="userInfo">
        <div class="userInfo__icons">
            <img src="@/assets/icons/basket.svg" v-if="$store.state.role == 'user'" @click="$router.push('/testPlatform/basket')"/>
            <div class="line1" v-if="$store.state.role == 'user'"></div>
            <img src="@/assets/icons/bell.svg" @click="openNotice = !openNotice"/>
            <div class="notice" v-if="openNotice">
            <img src="@/assets/icons/exit.svg" @click="openNotice = false" class="exit">
            <div class="notice__top">
                <p class="notice__top__title">Центр уведомлений</p>
                <div class="notice__top__count">5</div>
            </div>
            <div class="line"></div>
            <div class="notice__block">
                <div class="notice__block__in" v-for="item in notices" :key="item">
                    <div class="notice__block__in__top">
                        <div class="notice__block__in__top__left">
                            <img src="@/assets/icons/system.svg"/>
                            <div class="notice__block__in__top__left__info">
                                <div class="notice__block__in__top__left__info__top">
                                    <p class="name">Система</p>
                                    <p class="status"> {{ item.status }}</p>
                                    <p class="statusTime" :style="{color: item.timeStatus}">{{ item.timeStatusText }}</p>
                                </div>
                                <p class="date">{{ item.date }}</p>
                            </div>
                        </div>
                        <button v-if="item.isButton">Открыть документ</button>
                        <button v-if="item.isButtonCalendar">Открыть календарь</button>
                    </div>
                    <div class="notice__block__in__comments" v-if="item.type == 'correct'">
                        <div class="correct" v-for="correct in item.comments" :key="correct">
                            <div class="correct__top">
                                <img src="@/assets/icons/correct.svg"/>
                                <div class="correct__top__info">
                                    <p>{{ correct.name }}</p>
                                    <p class="status">Комментарий пользователя</p>
                                </div>
                            </div>
                            <textarea>To be fair, the whole album is about parodying how Slim Shady used to be with his wild lines, so it actually does hold some substance</textarea>
                            <button>Исправить</button>
                        </div>
                    </div>
                    <div class="line"></div>
                </div>  
            </div>
        </div>
        </div>
        <div class="userInfo__info">
            <label class="userInfo__info__left" for="imageWeb"> 
                <img :src="imagePreview || userInfo.image" alt="curator image" v-if="imagePreview || userInfo.image"/>
                <img src="@/assets/icons/profile.webp" v-else/>
                <input type="file" id="imageWeb" style="display: none;" accept="image/png, image/jpeg" 
                @change="handleFileChange"/>
            </label>
            <div class="userInfo__info__right">
                <p class="userInfo__info__right__spec" v-if="userInfo?.study_degree">{{userInfo?.study_degree?.title }}</p>
                <p class="userInfo__info__right__spec" v-else>{{userInfo?.role?.name }}</p>
                <p class="userInfo__info__right__name">{{userInfo?.name}}</p>
            </div>
        </div>
    </div>
</template>
<script>
import Notice from './newPlatform/notice.vue';
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
    components:{
        Notice
    },
    data(){
        return{
            isCurator: true,
            openNotice : false,
            imageForSend: '',
            imagePreview: '',
            notices: [
                {
                    name: 'Карина Н.',
                    status: 'добавлена в список клиентов',
                    date: '11.12.2024'
                },
                {
                    name: 'Карина Н.',
                    status: 'статус договора -',
                    timeStatusText: 'Истек',
                    timeStatus: 'red',
                    date: '11.12.2024'
                },
                {
                    name: 'Карина Н.',
                    status: 'продлила договор на',
                    timeStatusText: '3 месяца ',
                    timeStatus: 'green',
                    date: '11.12.2024'
                },
                {
                    name: 'Карина Н.',
                    status: 'дедлайн курса “Elementary” ',
                    timeStatusText: 'просрочен ',
                    timeStatus: 'red',
                    date: '11.12.2024'
                },
                {
                    name: 'Карина Н.',
                    status: 'дедлайн 1 программы Technology and Policy завершиться ',
                    timeStatusText: 'через 2 недели',
                    timeStatus: 'orange',
                    date: '11.12.2024'
                },
                {
                    name: 'Карина Н.',
                    status: 'подтвердила/отклонила документ ',
                    timeStatusText: ' “Резюме”',
                    timeStatus: 'Black',
                    isButton: true,
                    date: '11.12.2024'
                },
                {
                    name: 'Карина Н.',
                    status: 'запланировал встречу “Обсуждение с куратором”',
                    timeStatusText: '20 ноября 13:00',
                    isButtonCalendar: true,
                    timeStatus: 'black',
                    date: '11.12.2024'
                },
                {
                    name: 'Супервайзер Алия',
                    status: 'отправил(-а) Вам запрос на корректировку информации',
                    date: 'Вчера 14:30',
                    type: 'correct',
                    comments: [
                        {
                            name:'Massachusetts Institute of Technology',
                            comment: 'To be fair, the whole album is about parodying how Slim Shady used to be with his wild lines, so it actually does hold some substance'
                        },{
                            name:'University of London',
                            comment: 'I sometimes wonder how successful investors manage to accumulate enormous wealth from their investment endeavours because I am an avid investor. I currently have equity from a recent house sale that exceeds $545K, but Im not sure what to do with my money next. Is now the right moment to buy stocks, or should I wait for a better opportunity?'
                        },
                    ]
                },
                {
                    name: 'Карина Н.',
                    status: 'Встреча с "имя клиента" “Обсуждение с куратором””',
                    timeStatusText: '20 ноября 13:00',
                    isButtonCalendar: true,
                    timeStatus: 'black',
                    date: '11.12.2024'
                },
                {
                    name: 'Карина Н.',
                    status: 'загрузила оффер в First/second/third choice',
                    date: '11.12.2024'
                },
                
            ],
        }
    },
    computed:{
        ...mapGetters(["userInfo"])
    },
    methods: {
        handleFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.imagePreview = URL.createObjectURL(file);
                this.imageForSend= file;
                const formData = new FormData();
                formData.append('image',  this.imageForSend);
                try {
                 axios.post(`/platform/upload-image`, formData, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    });
                    toast.success('Успешно обновлено!')
                    this.originalUser = { ...this.editableUser };
                    this.$emit('closeModal');
                    setTimeout(() => {
                        this.$store.dispatch('requestUser');
                    }, 1000);
                } catch (response) {
                    toast.error(response.response.data.message);
                }
            }
        },
        navigateToRole(event) {
            const selectedRole = event.target.value;
            this.$store.state.role = selectedRole;
            if (selectedRole === 'user') {
                this.$router.push('/testPlatform');
            } else if (selectedRole === 'curator') {
                this.$router.push('/curator');
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.line1{
    border: 1px solid #0000001A;
}
.exit{
    filter: brightness(0) saturate(100%) invert(66%) sepia(8%) saturate(11%) hue-rotate(12deg) brightness(95%) contrast(84%);
    width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
    height: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
    position: absolute;
    top: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
    right: 40px;
    cursor: pointer;
}
    .line{
        border-top: 1px solid #0000001A;
        width: 100%;
    }
    .notice{
        background-color: white;
        position: absolute;
        width: 900px;
        max-height: 500px;
        overflow: auto;
        top: 110%;
        padding: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px)40px;
        display: flex;
        border: 1px solid grey;
        flex-direction: column;
        z-index: 999;
        right: 0%;
        gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        border-radius: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        &__top{
            display: flex;
            gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
            &__title{
                font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                font-weight: 600;
                color: #11415D;
            }
            &__count{
                width: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                height: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                background: #F8F8F8;
                border: 1px solid #0000001A;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #0000004D;
            }
        }
        &__block{
            display: flex;
            flex-direction: column;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            &__in{
            display: flex;
            flex-direction: column;
            gap: 40px;
            &__top{
                display: flex;
                justify-content: space-between;
                gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                &__left{
                    display: flex;
                    gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                    align-items: stretch;
                    &__info{
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        &__top{
                            display: flex;
                            flex-wrap: wrap;
                            gap: 5px;
                            align-items: center;
                            font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
                            .name, .statusTime{
                                font-weight: 600;
                            }
                        }
                        .date{
                            font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
                            color: #0000008A;
                        }
                    }
                }
            }
            &__comments{
                display: flex;
                flex-direction: column;
                gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            }
        }
        }
    }
    button{
        background-color: #11415D;
        color: white;
        padding: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.5208))), 12px);
        border: none;
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
        height: fit-content;
        border-radius: 10px;
        width: fit-content;
    }
    .correct{
    display: flex;
    flex-direction: column;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    width: 60%;
    &__top{
        display: flex;
        gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        &__info{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    textarea{
        width: 100%;
        padding: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        border-radius: 10px;
        background-color: #11415D;
        color: white;
        font-weight: 600;
        resize: none;
        min-height: 150px;
    }
}
    .userInfo{
        width: 100%;
        display: flex;
        gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        justify-content: flex-end;
        align-items: center;
        select{
            background: transparent;
            border: none;
        }
        &__icons{
            display: flex;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            padding: 10px min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            height: fit-content;
            box-shadow: 0px 0px 4px 1px #0000000D;
            border-radius: 8px;
            position: relative;
            img{
                object-fit: contain;
                cursor: pointer;
            }
        }
        &__info{
            display: flex;
            gap: 10px;
            align-items: center;
            &__left{
                img{
                    width: 60px;
                    height: 60px;
                    border-radius: 10px;
                    cursor: pointer;
                }
            }
            &__right{
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                font-weight: 700;
                &__spec{
                    font-size: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.5208))), 12px);
                    color: #FD2D2A;
                    text-transform: uppercase;
                }
                &__name{
                    font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
                }
            }
        }
    }
</style>