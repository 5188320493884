<template>
    <div class="customCard">
        <div class="customCard__left">
            <swiper 
                :slidesPerView="1" 
                :speed="1000"
                :pagination="{ 
                            clickable: true, 
                }"
                :lazy="true" 
                :loop="true"
            > 
                <swiper-slide v-for="(img) in item?.images" :key="img" > 
                    <img :src="img.image"/>
                </swiper-slide> 
            </swiper>
            <div class="bilim" :class="{supervisorButton: $store.state.role == 'supervisor'}">
                <img src="@/assets/icons/bilim.svg"/>
                <p>ВЫБОР СТУДЕНТА</p>
            </div>
        </div>
        <div class="customCard__right">
            <div class="customCard__right__top">
                <p class="customCard__right__top__name">
                    {{item?.country}}<span v-if="item?.state">,</span> {{item?.state}}<span v-if="item?.city">, </span>{{item?.city}}</p>
                <p class="customCard__right__top__spec">
                    {{item?.programName}}</p>
            </div>
            <div class="line"></div>
            <p class="customCard__right__rate" :class="{supervisorText: $store.state.role == 'supervisor'}">ТОП-<span>{{item?.subcategory?.category?.title}}</span><span v-if="item?.is_partner == true">Partner</span></p>
            <div class="customCard__right__buttons" v-if="isFavorite">
                <button class="customCard__right__buttons__red" @click="$router.push('/testPlatformFind/1')">Подробнее</button>
            </div>
            <div class="customCard__right__buttons" v-else>
                <button class="customCard__right__buttons__red" v-if="isAdmission" @click="changeAdmission()">Редактировать</button>
                <button class="customCard__right__buttons__red" @click="$router.push('/testPlatformFind/1')" v-if="isAdmission == false  && isWait == false">Подробнее</button>
                <button class="customCard__right__buttons__fav" v-if="$store.state.role == 'user' && item.is_favourite == 0 && isAdmission == null  && isWait == false" @click="addToFav(item.id)">
                    <img src="@/assets/icons/greyHeart.svg"/>
                    <p >В избранное</p>
                </button>
                <button class="customCard__right__buttons__fav" v-if="$store.state.role == 'user' && item.is_favourite == 1 && isAdmission == null  && isWait == false"  :class="{customFav: item.is_favourite == 1}" @click="deleteFromFav(item.id)">
                    <img src="@/assets/icons/fullHeart.svg"/>
                    <p>Убрать</p>
                </button>
                <button class="customCard__right__buttons__inAdmiss" v-if="($store.state.role == 'curator') && isAdmission == true  && isWait == false" @click="deleteFromAdmission()">
                    <p>Убрать</p>
                </button>
                <button class="customCard__right__buttons__admiss" v-if="($store.state.role == 'curator') && isAdmission == null && isClient && isWait == false" @click="toAdmission()">
                    <img src="@/assets/icons/admission.svg"/>
                    <p>Добавить в поступление</p>
                </button>
                <button class="customCard__right__buttons__admiss" v-if="($store.state.role == 'curator') && isWait" disabled>
                    <img src="@/assets/icons/blackClock.svg"/>
                    <p>В ожидании</p>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { Swiper, SwiperSlide,} from "swiper/vue"; 
import "swiper/css"; 
import "swiper/css/pagination"; 
import "swiper/css/navigation";  
import SwiperCore, { A11y, Autoplay } from "swiper"; 
import { Navigation, Pagination } from 'swiper'; 
import "swiper/swiper.min.css"; 
SwiperCore.use([Navigation,Pagination, A11y, Autoplay])
import axios from "axios";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
    components:{
        Swiper, SwiperSlide,
    },  
    props: ['isFavorite', 'isAdmission', 'item', 'userId', 'isClient', 'isWait'],
    data(){
        return{
            isCurator: true
        }
    },
    methods: { 
        changeAdmission(){
            this.$emit('changeAdmission')
        },
        deleteFromAdmission(){
            axios.delete(`/platform/curator/users/admissions/52?university_program_id=${this.item.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
            if (response.status === 202) {
                toast.success('Успешно удалено')
            } else {
                console.log(response.data);
            }
            })
            .catch((response) => { 
                console.log(response.response.data.errors);
            });
        },
        addToFav(id){
            const data = {
                university_program_id: id
            }
            axios.post('/platform/client/users/favourites', data , {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
            if (response.status === 201) {
                toast.success('Добавлено в избранное')
                this.$emit('newFav')
            } else {
                console.log(response.data);
            }
            })
            .catch((response) => { 
                console.log(response.response.data.errors);
            }); 
        },
        deleteFromFav(id){
            axios.delete(`/platform/client/users/favourites/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
            if (response.status === 202) {
                toast.success('Успешно удалено')
                this.$emit('newFav')
            } else {
                console.log(response.data);
            }
            })
            .catch((response) => { 
                console.log(response.response.data.errors);
            }); 
        },
        toAdmission(){
            const data = {
                user_id: this.userId,
                university_program_id: this.item.id
            }
            axios.post('/platform/curator/users/admissions', data , {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
            if (response.status === 201) {
                toast.success('Успешно добавлена')
                this.$emit('newAdmission')
            } else {
                console.log(response.data);
            }
            })
            .catch((response) => { 
                toast.error(response.response.data.message);
            }); 
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
    width: 100%;    
}
.customFav{
    color: #FD2D2A;
    border: 1px solid #FD2D2A !important;
    img{
        filter: brightness(0) saturate(100%) invert(38%) sepia(54%) saturate(7060%) hue-rotate(346deg) brightness(100%) contrast(98%);
    }
}
    .customCard{
        width: 100%;
        display: flex;
        justify-content: space-between;
        &__left{
            width: 25%;
            position: relative;
            img{
                width: 100%;
                border-radius: 10px;
                aspect-ratio: 1;
                object-fit: cover;
            }
            .bilim{
                position: absolute;
                z-index: 998;
                background-color: white;
                top: 10px;
                left: 10px;
                display: flex;
                gap: 5px;
                color: white;
                img{
                    width: 10px;
                }
                p{
                    font-size: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.5208))), 12px);
                }
                padding: 5px;
                border-radius: 5px;
                background-color: #FD2D2A;
                align-items: center;
            }
        }
        &__right{
            width: 73%;
            display: flex;
            flex-direction: column;
            gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
            &__top{
                display: flex;
                flex-direction: column;
                gap: 5px;
                &__name{
                    color: #0000008A;
                }
                &__spec{
                    font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                    font-weight: 700;
                }
            }
            &__rate{
                font-weight: 700;
                color: #FD2D2A;
                span{
                    color: #11415D;
                    margin-left: 5px;
                }
            }
            &__buttons{
                display: flex;
                gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                align-items: stretch;
                button{
                    padding: 10px;
                    border-radius: 5px;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;
                    img{
                        width: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                    }
                }
                &__red{
                    background-color: #FD2D2A;
                    color: white;
                    width: 35%;
                    border: none;
                }
                &__inAdmiss{
                    width: 20%;
                    border: 1px solid #FD2D2A !important;
                    background-color: white;
                    color: #FD2D2A;
                    border: none;
                }
                &__admiss{
                    width: 55%;
                    border: 1px solid #0000001A !important;
                    background-color: white;
                    color: #0000008A;
                    border: none;
                }
                &__fav{
                    width: 45%;
                    background-color: transparent;
                    border: 1px solid #00000010;
                    border-radius: 10px;
                }
            }
        }
        @media(max-width:768px){
            &__left{
                width: 32%;
                .bilim{
                    padding: 2px;
                    font-size: 8px;
                }
            }
            &__right{
                width: 66%;
                gap: 7px;
                &__top{
                    font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
                    &__spec{
                        font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
                    }
                }
                &__buttons{
                    font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
                    button{
                        padding: 5px;
                    }
                    &__red{
                        width: 50%;
                    }
                    &__fav{
                        display: none !important;
                    }
                }
            }
        }
    }
</style>