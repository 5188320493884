<template>
    <div class="addFileBack">
        <div class="addFile">
            <div class="addFile__top">
                <p class="addFile__top__title">Добавить файл</p>
                <p class="addFile__top__sub">Укажите данные товара и добавьте покупку пользователю</p>
            </div>  
            <div class="line"></div>
            <div class="addFile__select">
                <div class="customInput">
                    <span>Выберите категорию файла</span>
                    <customSelect :list="categories" v-model="category" :is-add-file="true"/>
                </div>
            </div>
            <div class="addFile__block">
                <div class="customInput">
                    <span>Ссылка</span>
                    <input type="text" placeholder="Ссылка на файл" v-model="link"/>
                </div>
                <p>*Иконка файла будет добавлена автоматически</p>
            </div>
            <div class="line"></div>
            <div class="addFile__buttons">
                <button class="addFile__buttons__save" @click="sendData()">Добавить</button>
                <button class="addFile__buttons__delete" @click="closeModal()">Закрыть</button>
            </div>
        </div>
    </div>
</template>
<script>
import customSelect from "@/components/customSelect.vue";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from "axios";
export default {
    data(){
        return{
            categories: [],
            category: '',
            link: ''
        }
    },
    components: {
        customSelect
    },
    props: [
      'isRedactor', 'isAdd'  
    ],
    methods: {
        closeModal(){
            this.$emit('closeModal')
        },
        async getPage() {
            await axios.get(`/platform/basic-document-types`)
            .then(res => {
                this.categories = res.data.data
            })
            .catch(err => {
            })
        },
        sendData() {
            if (!/^https?:\/\//.test(this.link)) {
                toast.error('Ошибка: ссылка должна начинаться с http:// или https://');
                return;
            }

            const processData = {
                type: this.category,
                link: this.link,
                user_id: 52
            };

            axios.post('/platform/curator/store-user-basic-documents', processData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            }).then((response) => {
                if (response.status === 201) {
                    toast.success('Успешно!');
                    this.$emit('getPageNew');
                } else {
                    toast.error(response.response.data.errors);
                }
            })
            .catch((response) => { 
                toast.error(response.response.data.errors.link);
            });
        }
    },
    async created(){
        this.getPage()
    }
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #0000001A;
    width: 100%;
}
    .addFileBack{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        z-index: 999;
        align-items: center;
        background-color: #0000007a;
    }
    .addFile{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        width: 33%;
        background-color: white;
        padding: 40px 60px;
        border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        &__top{
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: center;
            &__title{
                font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.2153))), 32px);
                color: #11415D;
                font-weight: 600;
            }
            &__sub{
                color: #0000008A;
            }
        }
        &__select{
            width: 100%;
        }
        &__block{
            display: flex;
            flex-wrap: wrap;
            gap: 4%;
            width: 100%;
            row-gap: 10px;
            P{
                color: #0000008A;   
            }
        }
    &__buttons{
        display: flex;
        width: 100%;
        gap: 4%;
        button{
            width: 48%;
            border: none;
            height: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
            border-radius: 10px;
        }
        &__save{
            color: white;
            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            background-color: #11415D;
            border: 1px solid #11415D;
            transition: all 0.5s ease;
            &:hover{
                color: white;
                background-color: #2076a7;
            }
        }
        &__delete{
            background-color: inherit;
            border: 1px solid #0000001A !important;
            color: #0000008A;
            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            transition: all 0.5s ease;
            &:hover{
                color: white;
                background-color: #11415D;
                border: 1px solid #11415D;
            }
        }
    }
    }
    
.customInput{
    position: relative;
    display: inline-block;
    width: 100%;
    cursor: pointer !important;
    span{
        position: absolute;
        top: -8px;
        left: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        background-color: #ffffff; /* Цвет фона, чтобы совпадал с фоном */
        padding: 0 10px;
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
        color: #c0c0c0; 
        z-index: 99;
    }
    input, select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0px min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        height: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
        border: 1px solid #e0e0e0; /* Цвет рамки */
        border-radius: 8px;
        font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        background-color: inherit;
        color: #000; /* Цвет текста */
        outline: none;
        img{
            width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            height: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        }
    }
    }
</style>