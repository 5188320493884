<template>
    <div class="rangeUI">
      <div class="rangeUI__function">
        <div class="rangeUI__function-ranges">
          <div class="rangeUI__function-ranges-in">
  <input
    type="range"
    class="range-min"
    :min="min"
    :max="max"
    :step="step"
    v-model="currentRanges.min"
    @input="rangeSwipe"
    @mouseup="$emit('updatemin', currentRanges.min)"
  />
  <p class="range-value" :style="getLabelStyle1(currentRanges.min)">
    {{ currentRanges.min }}{{value}}
  </p>
          </div>
          <div class="rangeUI__function-ranges-in">
            <input
              type="range"
              class="range-max"
              :min="min"
              :max="max"
              :step="step"
              v-model="currentRanges.max"
              @input="rangeSwipe"
              @mouseup="$emit('updatemax', currentRanges.max)"
            />
            <p class="range-value" :style="getLabelStyle(currentRanges.max)">
              {{ formatValue(currentRanges.max) }}{{value}}
            </p>
          </div>
        </div>
        <div
          class="rangeUI__function__line"
          :class="`rangeUI__function__line-${name}`"
        >
          <span
            class="rangeUI__function__line-active"
            :class="`rangeUI__function__line-active-${name}`"
            :style="activeLineStyle"
          ></span>
        </div>
      </div>
      <!-- <div class="rangeUI__values">
        <div class="rangeUI__values__box">
          <p class="rangeUI__values__box-title">Min</p>
          <input type="number" v-model="currentRanges.min" @input="changeManual('min')"/> 
        </div>
        <div class="rangeUI__values-mid"></div>
        <div class="rangeUI__values__box">
          <p class="rangeUI__values__box-title">Max</p>
          <input type="number" v-model="currentRanges.max" @input="changeManual('max')"/> 
        </div>
      </div> -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'RangeUI',
    props: {
      min: {
        type: Number,
        required: true,
      },
      max: {
        type: Number,
        required: true,
      },
      range: {
        type: Array,
        required: true,
      },
      step: {
        type: Number,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      value: {
        type: String,
        required: false
      }
    },
    data() {
      return {
        currentRanges: {
          min: this.range[0] ?? this.min,
          max: this.range[1] ?? this.max,
        },
      };
    },
    computed: {
      activeLineStyle() {
        const left = ((this.currentRanges.min - this.min) / (this.max - this.min)) * 100;
        const right = 100 - ((this.currentRanges.max - this.min) / (this.max - this.min)) * 100;
        return {
          left: `${left}%`,
          right: `${right}%`,
        };
      },
    },
    watch: {
      range: {
        handler(newVal) {
          this.currentRanges.min = newVal[0];
          this.currentRanges.max = newVal[1];
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      formatValue(value) {
        if (value % 1000 === 0) {
          return `${value / 1000}k`; // Убираем последние 3 нуля
        }
        return value.toString(); // Возвращаем число без изменений
      },
      rangeSwipe(event) {
        const minVal = parseInt(this.currentRanges.min);
        const maxVal = parseInt(this.currentRanges.max);
  
        if (maxVal - minVal < this.step) {
          if (event.target.className === 'range-min') {
            this.currentRanges.min = maxVal - this.step;
          } else {
            this.currentRanges.max = minVal + this.step;
          }
        }
      },
      getLabelStyle1(value) {
        const percentage = ((value - this.min) / (this.max - this.min)) * 100;
        return {
          left: `calc(${percentage}% + 7px)`, // Центрирование текста под кружочком
          position: 'absolute',
          top: '30px', // Под ползунком
        };
      },
      getLabelStyle(value) {
        const percentage = ((value - this.min) / (this.max - this.min)) * 100;
        return {
          left: `calc(${percentage}% - 7px)`, // Центрирование текста под кружочком
          position: 'absolute',
          top: '30px', // Под ползунком
        };
      },
      changeManual(type) {
        if (type === 'min') {
          this.currentRanges.min = Math.max(
            this.min,
            Math.min(this.currentRanges.min, this.currentRanges.max - this.step)
          );
          this.$emit('updatemin', this.currentRanges.min);
        } else if (type === 'max') {
          this.currentRanges.max = Math.min(
            this.max,
            Math.max(this.currentRanges.max, this.currentRanges.min + this.step)
          );
          this.$emit('updatemax', this.currentRanges.max);
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  /* CSS styles remain the same */
  .rangeUI__function-ranges-in {
    position: relative;
    .range-value {
      position: absolute;
      top: 25px; // Расположение под кружочком
      font-size: 14px;
      font-weight: 600;
      color: #000000;
      white-space: nowrap;
      transform: translateX(-50%);
    }
  }
  .rangeUI {
    width: 100%;
    &-reverse {
      display: flex;
      flex-direction: column-reverse;
      gap: 20px;
      .rangeUI__function {
        margin-bottom: 0;
      }
    }
    &__function {
      margin-bottom: 30px;
      position: relative;
      width: 100%;
      z-index: 0;
      &-ranges {
        position: absolute;
        z-index: 2;
        width: 100%;
        top: -10px;
        left: 0;
        input {
          outline: none;
          border: none;
          cursor: pointer;
          position: absolute;
          width: 100%;
          background: none;
          pointer-events: none;
          -webkit-appearance: none;
          padding: 0;
          margin: 0;
        }
        input[type='range']::-webkit-slider-thumb {
          height: 25px;
          width: 25px;
          border-radius: 50%;
          background: white;
          border: 1px solid rgba(0, 0, 0, 0.103);
          pointer-events: auto;
          -webkit-appearance: none;
        }
      }
      &__line {
        width: 100%;
        height: 2px;
        background: #d9d9d9;
        &-active {
          height: 100%;
          position: absolute;
          border-radius: 5px;
          background: #FD2D2A;
        }
      }
    }
    &__values {
      display: flex;
      align-items: center;
      gap: 30px;
      &__box {
        padding: 5px 15px 10px 10px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        &-title {
          font-size: 14px;
          font-weight: 600;
          color: #777777;
        }
        input {
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          color: #2b2b2b;
          border: none;
          background: inherit;
        }
      }
      &-mid {
        min-width: 10px;
        height: 1px;
        background: #d9d9d9;
      }
    }
  }
  </style>
  