<template>
    <div class="addProductBack">
        <div class="addProduct">
            <div class="addProduct__top">
                <p class="addProduct__top__title" v-if="isAdd">Добавить покупку</p>
                <p class="addProduct__top__title" v-else>Редактировать покупку</p>
                <p class="addProduct__top__sub" v-if="isAdd">Укажите данные товара и измените покупку пользователю</p>
                <p class="addProduct__top__sub" v-else>Укажите данные товара и измените покупку пользователю</p>
            </div>  
            <div class="line"></div>
            <div class="addProduct__select">
                <div class="customInputWhite">
                    <label>Выберите категорию товара</label>
                    <customSelect :list="buyCategory" v-model="selectedCategory" @change="newMainText()"/>
                </div>
            </div>
            <div class="addProduct__block">
                <div class="customInputWhite">
                    <label>Текст основной</label>
                    <customSelect :list="mainText" v-model="mainTextId"/>
                </div>
                <div class="customInputWhite">
                    <label>Текст дополн.</label>
                    <input type="text" placeholder="Текст" v-model="comment"/>
                </div>
                <p>*Фото файла будет добавлено автоматически</p>
            </div>
            <div class="line"></div>
            <div class="addProduct__buttons">
                <button class="addProduct__buttons__save" v-if="isAdd" @click="newProduct()">Добавить</button>
                <button class="addProduct__buttons__save" v-else @click="redactorProduct()">Сохранить</button>
                <button class="addProduct__buttons__delete" @click="closeModal()">Закрыть</button>
            </div>
        </div>
    </div>
</template>
<script>
import customSelect from '../customSelect.vue';
import axios from "axios";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
    components: {
        customSelect
    },
    data(){
        return{
            mainText: [],
            stips: [],
            cources: [],
            mainTextId: null,
            selectedCategory: null,
            comment: '',
            buyCategory: [
                {
                    id: 0,
                    title: 'Наставничество',
                },
                {
                    id: 1,
                    title: 'Индивидуальные курсы',
                },
                {
                    id: 2,
                    title: 'Групповые курсы',
                },
                {
                    id: 3,
                    title: 'Подготовка к тестам',
                },
            ],
        }
    },
    props: [
      'isRedactor', 'isAdd' , 'item' 
    ],
    methods: {
            newProduct(){
                if(this.selectedCategory != null && this.mainTextId != ''){
                    const data = {
                        client_id: 52,
                        comment: this.comment,
                        status: 2
                    };
                    if (this.selectedCategory == 0) {
                        data.package_id = this.mainTextId;
                    } else {
                        data.course_id = this.mainTextId;
                    }
                    axios.post(`/platform/curator/baskets/buy`, data , {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        }
                    })
                    .then((response) => {
                        if (response.status === 201) {
                            toast.success('Успешно добавлено!')
                            this.$emit('newProduct')
                            this.closeModal()
                        } else {
                        }
                    })
                    .catch((response) => { 
                    });
                } else {
                    toast.error('Заполните все поля!')
                }
            },
            redactorProduct(){
                const data = {
                    client_id: 52,
                    comment: this.comment,
                    status: 2
                };
            if (this.selectedCategory == 0) {
                data.package_id = this.mainTextId;
            } else {
                data.course_id = this.mainTextId;
            }
            axios.put(`/platform/curator/baskets/buy/${this.item.id}`, data , {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
                console.log(response.status);
            if (response.status === 202) {
                toast.success('Успешно обновлено!')
                this.$emit('newProduct')
                this.closeModal()
            } else {
            }
            })
            .catch((response) => { 
            });
            },
        newMainText(){
            if(this.selectedCategory == 0){
                this.mainText = this.stips
            }
            if(this.selectedCategory == 1){
                this.mainText = this.courses.filter(item => item.type == 0)
            }
            if(this.selectedCategory == 2){
                this.mainText = this.courses.filter(item => item.type == 2)
            }
            if(this.selectedCategory == 3){
                this.mainText = this.courses.filter(item => item.type == 3)
            }
        },
        closeModal(){
            this.$emit('closeModal')
        },
        async getPage(){
            await axios.get(`/platform/packages`, {
            })
            .then(res => {
                this.stips = res.data.data
            })
            .catch(err => {
            })
            await axios.get(`/platform/courses`)
            .then(res => {
                this.courses = res.data.data
            })
            .catch(err => {
            }) 
        }
    },
    async created(){
        this.getPage()
    },
    mounted(){

    }
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #0000001A;
    width: 100%;
}
    .addProductBack{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        z-index: 999;
        align-items: center;
        background-color: #0000007a;
    }
    .addProduct{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        width: 33%;
        background-color: white;
        padding: 40px 60px;
        border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        &__top{
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: center;
            &__title{
                font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.2153))), 32px);
                color: #11415D;
                font-weight: 600;
            }
            &__sub{
                color: #0000008A;
            }
        }
        &__select{
            width: 100%;
        }
        &__block{
            display: flex;
            flex-wrap: wrap;
            gap: 4%;
            row-gap: 10px;
            P{
                color: #0000008A;   
            }
            .customInputWhite{
                width: 48%;
            }
        }
    &__buttons{
        display: flex;
        width: 100%;
        gap: 4%;
        button{
            width: 48%;
            border: none;
            height: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
            border-radius: 10px;
        }
        &__save{
            color: white;
            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            background-color: #11415D;
            border: 1px solid #11415D;
            transition: all 0.5s ease;
            &:hover{
                color: white;
                background-color: #2076a7;
            }
        }
        &__delete{
            background-color: inherit;
            border: 1px solid #0000001A !important;
            color: #0000008A;
            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            transition: all 0.5s ease;
            &:hover{
                color: white;
                background-color: #11415D;
                border: 1px solid #11415D;
            }
        }
    }
    }
</style>