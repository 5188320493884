<template>
    <div class="mainStyle" :class="{mainStyle3: $store.state.role == 'supervisor'}">
        <div class="mainStyle__left">
            <platformTable />
        </div>
        <div class="mainStyle__right">
            <div class="mainStyle__right__top">
                <div class="mainStyle__right__top__left">
                    <p class="mainStyle__right__top__left__title">Программы в ожидании</p>
                </div>
                <div class="mainStyle__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="line"></div>
            <div class="programs">
                <div class="programs__in" v-for="item in programs" :key="item">
                    <NewCardComponent :item="item" :is-wait="true"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NewCardComponent from "@/components/newCardComponent.vue";
import customSelect from "@/components/customSelect.vue";
import userInfo from "@/components/userInfo.vue";
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import "swiper/swiper.min.css";
import NewProgram from "@/components/newPlatform/newProgram.vue";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow])
import axios from "axios";
import platformTable from "@/components/platformTable.vue";
import ProgramsComponent from "@/components/programsComponent.vue";
export default {
    components: {
        Swiper,
        SwiperSlide,platformTable,
        ProgramsComponent,
        userInfo,
        NewCardComponent,
        NewProgram,
        customSelect
    },
    data() {
        return {
            programs: [],
        };
    },
    methods:{
        async getPrograms(){
            await axios.get(`/V1/universityPrograms`, {
            headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
        })
            .then(res => {
                this.programs = res.data.universityPrograms
            })
            .catch(err => {
            })
        }
    },
    async created() {
        this.getPrograms();
    },
};
</script>

<style lang="scss" scoped>
.programs {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    &__in {
        width: 49%;
    }

    @media(max-width:768px) {
        row-gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

        &__in {
            width: 100%;
        }
    }
}
</style>